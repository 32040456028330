import React from "react";

function AppleLogoSVG({ height = "24" }) {
    return (
        <svg
            height={height}
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.1266 17.9611C18.7787 18.7646 18.367 19.5043 17.89 20.1843C17.2398 21.1114 16.7074 21.7531 16.2971 22.1095C15.6611 22.6944 14.9796 22.9939 14.2499 23.011C13.726 23.011 13.0942 22.8619 12.3588 22.5595C11.621 22.2585 10.9429 22.1095 10.3229 22.1095C9.67272 22.1095 8.97536 22.2585 8.22944 22.5595C7.48239 22.8619 6.88057 23.0195 6.42044 23.0351C5.72067 23.0649 5.02317 22.7569 4.32694 22.1095C3.88257 21.7219 3.32676 21.0575 2.66091 20.1162C1.94651 19.111 1.35918 17.9455 0.899051 16.6166C0.40627 15.1813 0.159241 13.7914 0.159241 12.4458C0.159241 10.9044 0.492305 9.575 1.15943 8.46096C1.68373 7.56611 2.38123 6.86023 3.25421 6.34204C4.12719 5.82385 5.07044 5.55978 6.08625 5.54289C6.64206 5.54289 7.37094 5.71481 8.27672 6.0527C9.17994 6.39173 9.75989 6.56366 10.0142 6.56366C10.2043 6.56366 10.8485 6.36263 11.9407 5.96184C12.9735 5.59016 13.8452 5.43627 14.5594 5.49689C16.4944 5.65306 17.9482 6.41586 18.915 7.79014C17.1844 8.83874 16.3283 10.3074 16.3454 12.1915C16.361 13.6591 16.8934 14.8803 17.9397 15.85C18.4139 16.3 18.9434 16.6478 19.5326 16.8949C19.4048 17.2654 19.27 17.6203 19.1266 17.9611ZM14.6886 0.49724C14.6886 1.6475 14.2683 2.7215 13.4307 3.71558C12.4199 4.89734 11.1972 5.58022 9.87133 5.47247C9.85444 5.33447 9.84464 5.18924 9.84464 5.03662C9.84464 3.93237 10.3254 2.7506 11.179 1.78435C11.6052 1.29512 12.1473 0.888327 12.8046 0.563824C13.4605 0.244162 14.0809 0.0673822 14.6644 0.0371094C14.6815 0.190881 14.6886 0.344663 14.6886 0.497225V0.49724Z"
                fill="black"
            />
        </svg>
    );
}

export default AppleLogoSVG;
