export const navigationList = [
    {
        name: "Beranda",
        link: "/",
    },
    {
        name: "Tentang",
        link: "#tentang",
    },
    {
        name: "Tim",
        link: "#tim",
    },
    {
        name: "Kontak",
        link: "#kontak",
    },
];
